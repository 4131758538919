import React, { Fragment } from 'react';
import moment from 'moment';
import { AiOutlineClockCircle } from 'react-icons/ai';
import './style.scss';
import Divider from '../Divider';

type Props = {
    propertyMaster: any
}

function TimeDetails(props: Props) {

    const propMaster = props.propertyMaster;

    const formatDate = (time: any) => {
        if (time) {
            // Check correct time format and split into components
            time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

            if (time.length > 1) { // If time format correct
                time = time.slice(1);  // Remove full string match value
                time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
                time[0] = +time[0] % 12 || 12; // Adjust hours
            }
            time.splice(3, 1);
            return time.join(''); // return adjusted time or original string
        } else return "";
    }

    const extractTime = (dateTime: string) => {
        if(dateTime == null || dateTime == undefined)
            return;

        var data = dateTime.split(" ");
        var time = data[1].split(":");
        var hours = time[0];
        var minutes = time[1];
        return hours+":"+minutes+" "+data[2];
    }

    return (
        (propMaster.checkintime && propMaster.checkouttime) ? <div /> :
            <Fragment>
                <Divider />
                <div className="time-details-container">
                    <h4><strong>Time</strong></h4>
                    <p className="description">
                        <div className="time-list">
                            <div className="time-type-card m-2">
                                <AiOutlineClockCircle className="icon" />
                                <div className="name">Check-in Time</div>
                                <div className="type">{extractTime(propMaster.checkIn)} <span className="pl-1 pr-1"></span> {formatDate(propMaster.checkinupto)}</div>
                            </div>
                            <div className="time-type-card m-2">
                                <AiOutlineClockCircle className="icon" />
                                <div className="name">Check-out Time</div>
                                <div className="type">{extractTime(propMaster.checkout)} <span className="pl-1 pr-1"></span> {formatDate(propMaster.checkoutupto)}</div>
                            </div>
                        </div>
                    </p>
                </div>

            </Fragment>
    )
}

export default TimeDetails;
