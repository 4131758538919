import {
  actionDispatcher,
  newDotNetActionDispatcher,
} from "store/actionDispatcher";
import API from "api-handler/apiType";
import NewDotNetAPI from "api-handler/newDotNetApiType";

export const MasterActionType = {
  GET_HOME_PAGE_DATA: "GET_HOME_PAGE_DATA",
  GET_FOOTER_LINK_DATA: "GET_FOOTER_LINK_DATA",
};

export const getHomePageData = (params: object) => (dispatch: any) => {
  return newDotNetActionDispatcher(
    params,
    MasterActionType.GET_HOME_PAGE_DATA,
    NewDotNetAPI.getHomePageDetails,
    dispatch,
    "get"
  );
};

export const emailSubscribe = (email: string) => (dispatch: any) => {
  return newDotNetActionDispatcher(
    "",
    "",
    `${NewDotNetAPI.emailSubscribe}${email}`,
    dispatch,
    "post"
  );
};

export const getFooterLinkData = (params: object) => (dispatch: any) => {
  return actionDispatcher(
    params,
    MasterActionType.GET_FOOTER_LINK_DATA,
    API.getFooterLinkData,
    dispatch,
    "get"
  );
};
