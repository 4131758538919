import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";
import useCheckMobileDevice from "helpers/useCheckMobileDevice";
import { AppDispatch } from "helpers/GenericType";
import { getPropertyReviewList } from "../../store/actions/ReviewActions";
import AllReviews from "./allReviews";
import Rating from "./rating";
import ReviewCard from "./reviewCard/reviewCard";
import styles from "./style.module.scss";

type Props = {
  flatId: string;
  reviewData: Review;
};

interface Review  {
  reviewHeading : string,
  reviewTypeDetails : [],
  reviewDetailsByPeople? : []
}
const Reviews = (props: Props) => {
  const dispatch: AppDispatch = useDispatch();
  const { hotelId }: any = useParams();
  const isMobile = useCheckMobileDevice();
  const [reviewData, setReviewData] = useState({
    totalReviews: 0,
    overallRating: 0,
    reviews: [],
    rating: [],
  });
  const [showMore, setShowMore] = useState(false);
  const [defaultReviewId, setDefaultReviewId] = useState(0);
  const maxReviewsLimit = isMobile ? 3 : 4;
  const { reviews, rating } = reviewData;

  const getReviews = (flatId: string) => {
    dispatch(getPropertyReviewList(flatId))
      .then((res: any) => {
        setReviewData(res.data);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const reviewWrapper = useMemo(
    () => (
      <div className={styles.headingWrapper}>
        <span>★</span>
        <span>{reviewData.overallRating || 0}</span>
        <span>·</span>
        <span>{reviewData.totalReviews || 0} Reviews</span>
      </div>
    ),
    [reviewData]
  );


  const handleViewMore = (reviewId: number) => {
    setShowMore(true);
    if (reviewId) {
      setDefaultReviewId(reviewId);
    } else {
      setDefaultReviewId(0);
    }
  };

  const closeAllReview = () => {
    setShowMore(false);
    setDefaultReviewId(0);
  }

  useEffect(() => {
    if (props.flatId) {
      getReviews(props.flatId);
    }
  }, [props.flatId]);

  return (
    <div className={styles.ratingWrapper} id='review-section'>
      {reviewWrapper}
       {!isMobile && reviewData.rating && reviewData.rating.length > 0 && <Rating ratings={reviewData.rating} inOneRow={false} />}
       <div className={styles.scrollContainer}>
        <div className={styles.reviewCardWrapper}>
          {reviewData?.reviews && reviewData?.reviews.length > 0 && reviewData?.reviews.slice(0, maxReviewsLimit).map((review: any, index: number) => (
            <div className={styles.reviewCard} key={index}>
              <ReviewCard review={review} handleShowMore={handleViewMore} />
            </div>
          ))}
        </div>
      </div>
      {reviewData?.reviews && reviewData.reviews.length > maxReviewsLimit && (
        <button
          className={styles.showMoreBtn}
          onClick={() => {
            handleViewMore(0);
          }}
        >
          Show all {reviewData.reviews.length} reviews
        </button>
      )}
      {showMore && (
        <AllReviews
          showMore={showMore}
          handleClose={closeAllReview}
          reviews={reviewData.reviews}
          ratings={reviewData.rating}
          reviewWrapper={reviewWrapper}
          defaultReview={Number(defaultReviewId)}
        />
      )}
    </div>
  );
};

export default Reviews;
