import { useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { useDispatch, useSelector } from "react-redux";
import Auth from "./Auth";
import { Pattern } from "utilities/RegexPattern";
import { Notification } from "shared-components";
import { AppDispatch } from "helpers/GenericType";
import {
  updateProfile,
  verifyOtp,
  loginAndSignup,
  loginViaGoogle,
  getUserData,
} from "store/actions";
import { format } from "date-fns";

type Props = {
  isHost?: boolean;
  activeOption: number;
  handleClose: () => void;
};

const AuthContainer = (props: Props) => {
  const dispatch: AppDispatch = useDispatch();
  const { user }: any = useSelector((state) => state);

  const [error, setError] = useState("");
  const [type, setType] = useState(
    props.activeOption === 1 ? "LOGIN" : "SIGNUP"
  );
  const [mobile, setMobile] = useState("");
  const [countryCode, setCountryCode] = useState("+91");
  const [showLoader, setShowLoader] = useState(false);
  const [showGoogleLoader, setShowGoogleLoader] = useState(false);
  const [currentOption, setCurrentOption] = useState(1);
  const [imageFile, setImageFile] = useState(null) as any;

  const handleLogin = (e: any) => {
    e.preventDefault();
    if (Pattern.mobile.pattern.test(e.target.mobile.value)) {
      const params = {
        mobile: e.target.mobile.value,
        countryCode: e.target.countryCode.value,
      };
      setShowLoader(true);
      dispatch(loginAndSignup(params.mobile))
        .then((res: any) => {
          if (res) {
            setType(res.type);
            setMobile(e.target.mobile.value);
            setCountryCode(e.target.countryCode.value);
            setCurrentOption(2);
            setError("");
          } else setError("Invalid mobile number.");
        })
        .finally(() => setShowLoader(false));
    } else {
      Notification("Login", "Please enter valid mobile number", "warning");
    }
  };

  const handleProfile = (e: any) => {
    e.preventDefault();

    if (Pattern.email.pattern.test(e.target.email.value)) {
      const params = {
        id: user.user ? user.user.id : "",
        name: e.target.name.value,
        dob: e.target.dob.value,
        gender: e.target.gender.value,
        email: e.target.email.value,
        is_mobile_verified: 1,
        file: imageFile,
      };
      setShowLoader(true);
      dispatch(updateProfile(params))
        .then((res: any) => {
          handleClose();
          localStorage.setItem("token", "Bearer " + user.token);
          localStorage.setItem("userData", JSON.stringify(user));
        })
        .finally(() => setShowLoader(false));
    } else {
      Notification("Login", "Please enter valid email", "warning");
    }
  };

  const handleOtp = (otp: string) => {
    setShowLoader(true);
    const params = new URLSearchParams({
      grant_type: "password",
      scope: "ConnectApi",
      client_id: "web.guest",
      client_secret: "eNuscFFwA8X4tJEYsQyjndUkrQhxN3Xu",
      Username: mobile,
      Password: "",
      otp: otp,
    });
    dispatch(verifyOtp(params))
      .then((res: any) => {
        if (res && res.access_token) {
          localStorage.setItem("token", "Bearer " + res.access_token);
          fetchUserData(res.access_token);
        } else {
          setError("otp verification failed.");
        }
      })
      .catch((err: any) => setError("Invalid OTP"))
      .finally(() => setShowLoader(false));
  };

  const fetchUserData = (token: string) => {
    setShowLoader(true);
    const params = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    dispatch(getUserData(params))
      .then((res: any) => {
        if (res) {
          localStorage.setItem("userData", JSON.stringify(res.data));
          if (props.activeOption === 1) {
            setCurrentOption(2);
            handleClose();
          } else {
            setCurrentOption(3);
          }
          setError("");
        } else {
          setError("Failed to fetch user data.");
        }
      })
      .catch((err: any) => setError("Error fetching user data"))
      .finally(() => setShowLoader(false));
  };

  const handleClose = () => props.handleClose();

  const handleBack = () => {
    setError("");
    setCurrentOption(1);
  };

  const handleGoogleLogin = async () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    try {
      const result = await firebase.auth().signInWithPopup(provider);
      const user = result.user;
      const idToken = await user?.getIdToken();

      if (idToken) {
        setShowGoogleLoader(true);
        dispatch(loginViaGoogle({ idToken }))
          .then((res: any) => {
            if (res && res.token) {
              localStorage.setItem("token", `Bearer ${res.token}`);
              localStorage.setItem("userData", JSON.stringify(res.user));
              setError("");
              handleClose();
            } else if (res && !(res.user && res.user.id)) {
              setCurrentOption(4);
            } else {
              setError("Email verification failed!");
            }
          })
          .finally(() => setShowGoogleLoader(false));
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const firebaseConfig = {
      apiKey: "AIzaSyD51mV-1l5Ol4W6bW-kwgVzvbsoaFMT-oc",
      authDomain: "blablastay.firebaseapp.com",
      projectId: "blablastay",
      storageBucket: "blablastay.appspot.com",
      messagingSenderId: "991051766653",
      appId: "1:991051766653:web:4638f34050b8f693880a14",
      measurementId: "G-JCDQV1ZWBV",
    };
    firebase.initializeApp(firebaseConfig);
  }, []);

  return (
    <Auth
      error={error}
      type={type}
      currentOption={currentOption}
      showLoader={showLoader}
      isHost={props.isHost}
      mobile={mobile}
      handleBack={handleBack}
      handleClose={handleClose}
      handleOtp={handleOtp}
      handleLogin={handleLogin}
      handleProfile={handleProfile}
      setImageFile={setImageFile}
      handleGoogleLogin={handleGoogleLogin}
      showGoogleLoader={showGoogleLoader}
    />
  );
};

export default AuthContainer;
