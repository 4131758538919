import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import LandingPage from "./LandingPage";
import { getHomePageData } from "store/actions";
import { AppDispatch } from "helpers/GenericType";
import useCheckMobileDevice from "helpers/useCheckMobileDevice";

function LandingPageContainer() {
  const isMobile = useCheckMobileDevice();
  const dispatch: AppDispatch = useDispatch();
  const { master }: any = useSelector((state) => state);
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    console.log("getHomePageData");
    setShowLoader(true);
    dispatch(getHomePageData({})).finally(() => setShowLoader(false));
  }, [dispatch]);

  let homePageData = {};

  if (Object.keys(master).length !== 0) {
    homePageData = master;
  }

  return (
    <LandingPage
      isMobile={isMobile}
      showLoader={showLoader}
      homePageData={homePageData}
    />
  );
}

export default LandingPageContainer;
