import { useState } from "react";
import Reviews from "../../reviews";
import About from "./About";
import Policies from "./Policies";
import Amenities from "./Amenities";
import HostDetails from "./HostDetails";
import TimeDetails from "./TimeDetails";
import MapLocation from "./MapLocation";
import Arrangements from "./Arrangements";
import SimilarHotels from "./SimilarHotels";
import ImageGalleryContainer from "./ImageGallery";
import HotelDetailsHeader from "./HotelDetailsHeader";
import EnquiryFormContainer from "components/common-components/EnquiryForm";
import HightlightSection from "./HightlightSection";

type Props = {
  flatId: string;
  hotelDetails: any;
  isMobile: boolean;
  handleReserve: () => void;
  handleAvailability: () => void;
  handleGroupProperty: (subPrppertyId: any) => void;
  handleIndividualProperty: (hote: any) => void;
  showLoader?: boolean;
  checkInDate: string;
  checkOutDate: string;
  handleCheckIndate: (date: any) => void;
  handleCheckOutdate: (date: any) => void;
  handleGuests: (action: string, type: string) => void;
  guests: {
    adult: number;
    children: number;
    infant: number;
    [key: string]: number;
  };
  reviews?: any;
  getAndSetReviews: (count?: number, avgRating?: number) => void;
};

function HotelDetails(props: Props) {
  
  const details = props.hotelDetails.parent;
  const images = details ? details.images : [];
  const policies = details?.policies || [];
  const amenities = details?.amenities || [];
  const propertyMaster = details || {};
  const subPropertyData = props.hotelDetails.child || [];
  // const showGroup = details.sub_property_show_group_individual;
  const activities = details?.activities || [];
  const broadband = details?.broadBands || [];
  const kitchennfood = details?.kitchens || [];
  const sleepingArrangements = details?.sleepingArrangement || [];
  const reviewData = details?.reviews || {};

  return (
    <>
      <HotelDetailsHeader
        reviews={reviewData}
        propertyMaster={propertyMaster}
      />

      <ImageGalleryContainer isMobile={props.isMobile} images={images} />

      <div className="displayFlex">
        <div className="displayItem">
           <HostDetails propertyMaster={propertyMaster} />
          <HightlightSection policies={policies} amenities={amenities} />
          <About
            propertyMaster={propertyMaster}
            description={propertyMaster.about || ""}
          />
          <Amenities amenities={amenities} />
           <TimeDetails propertyMaster={propertyMaster} />
        </div>
        <div className="displayItem2">
          <div className="hotel-detail-enquiry">
            <EnquiryFormContainer
              showButton={true}
              handleReserve={props.handleReserve}
              checkAvailability={props.handleAvailability}
              reviews={null}
              showLoader={props.showLoader}
              checkInDate={props.checkInDate}
              checkOutDate={props.checkOutDate}
              handleCheckIndate={props.handleCheckIndate}
              handleCheckOutdate={props.handleCheckOutdate}
              guests={props.guests}
              handleGuests={props.handleGuests}
            />
          </div>
        </div>
      </div>

      <Arrangements
        activities={activities}
        broadband={broadband}
        kitchenFood={kitchennfood}
        sleepingArrangements={sleepingArrangements}
      />

      {/* <Policies policies={policies} /> */}

      <Reviews flatId={props.flatId} reviewData={reviewData} />

      <MapLocation
        latitude={propertyMaster.latitude}
        longitude={propertyMaster.longitude}
      />

      <SimilarHotels
        showGroup="1"
        flatId={propertyMaster.subPropertyId}
        propertyMaster={propertyMaster}
        subPropertyData={subPropertyData}
        handleGroupProperty={props.handleGroupProperty}
        handleIndividualProperty={props.handleIndividualProperty}
      />
    </>
  );
}

export default HotelDetails;
